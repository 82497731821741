<!--
 * @Date: 2021-03-23 20:55:29
 * @LastEditors: frank
 * @LastEditTime: 2021-05-10 14:51:16
 * @FilePath: /shop_frontend/src/components/chooseLink.vue
 * @Description: Description
-->
<template>
  <section class="chooseLink">
    <a-modal
      title="选择链接"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      width="60%"
      destroyOnClose
    >
      <div >
        <a-tabs
          default-active-key="1"
          tab-position="left"
          :style="{ height: '100%' }"
          @change="onChange"
        >
          <a-tab-pane key="1" tab="商品链接">
            <a-table
              :columns="columns1"
              :data-source="dataSource1"
              :pagination="pagination1"
              bordered
              @change="changeTable1"
              :rowSelection="{type: 'radio',onChange: onChangeRow,columnTitle:'选择',}"
            >
              <div slot="name" slot-scope="text, record" class="name__box">
                <img
                  :src="record.picture_url"
                  alt=""
                  v-if="record.picture_url"
                  style="
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    margin-right: 4px;
                  "
                />
                <span>{{ record.name }}</span>
              </div>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="2" tab="商品分类">
            <a-table
              :columns="columns2"
              :data-source="dataSource2"
              :pagination="pagination2"
              bordered
              @change="changeTable2"
              :rowSelection="{type: 'radio',onChange: onChangeRow,columnTitle:'选择',}"
            >
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="3" tab="页面链接">
            <a-table
              :columns="columns3"
              :data-source="dataSource3"
              :pagination="false"
              :rowSelection="{type: 'radio',onChange: onChangeRow,columnTitle:'选择',}"
            >
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-modal>
  </section>
</template>

<script>
export default {
  name: "chooseLink",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeKey: "1",
      dataSource1: [],
      dataSource2: [],
      dataSource3: [],
      columns1: [
        {
          title: "商品信息",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 300
        },
        {
          title: "商品货号",
          dataIndex: "number",
        },
        {
          title: "链接",
          dataIndex: "link",
        },
      ],
      columns2: [
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "链接",
          dataIndex: "link",
        },
      ],
      columns3: [
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "链接",
          dataIndex: "link",
        },
      ],
      page1: 1,
      page2: 1,
      pagination1: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      pagination2: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      selectedList: []
    };
  },
  components: {},
  computed: {
    params1() {
      return { page: this.page1 };
    },
    params2() {
      return { page: this.page2 };
    },
  },
  watch: {
      visible (nVal) {

          if (nVal) {
              this.selectedList = []
          }

      }
  },
  created() {
    this.initTable1();
    this.initTable2();
    this.initTable3();
  },
  mounted() {},
  methods: {
      onChangeRow (selectedRowKeys,selectedRows) {
          console.log(selectedRowKeys, selectedRows, '---')
          this.selectedList = selectedRows
      },
    changeTable1(pagination) {
      this.page1 = pagination.current;
      this.initTable1();
    },
    changeTable2(pagination) {
      console.log(pagination, 'pagination---2')
      this.page2 = pagination.current;
      this.initTable2();
    },
    handleOk() {
        console.log(this.selectedList, 'this.selectedList')

        this.$emit("ok",this.selectedList);
    },
    handleCancel() {
      this.$emit("cancel");
    },
    onChange(activeKey) {
      console.log(activeKey, "activeKey");
      this.activeKey = activeKey;
      this[`initTable${activeKey}`]();
    },
    initTable1() {
      this.$axios.get("/goods_links/", { params: this.params1 }).then((res) => {
        console.log(res);
        this.dataSource1 = res.results || [];
        this.pagination1.total = res.count || 0;
      });
    },
    initTable2() {
      this.$axios
        .get("/category_links/", { params: this.params2 })
        .then((res) => {
          console.log(res);
          this.dataSource2 = res.results || [];
          this.pagination2.total = res.count || 0;
        });
    },
    initTable3() {
      this.$axios.get("/page_links/").then((res) => {
        console.log(res);
        this.dataSource3 = res || [];
      });
    },
  },
};
</script>

<style scoped lang="less">
.name__box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
</style>
